// import "./staking.css"

import React, { useEffect, useRef, useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Site, SiteCardSmall, SiteCardDetailed } from "@/components/site-card"
import { Coin } from "@/components/coin-card"

import Layout from "@/templates/layout"
import FAQs from "@/components/faqs"
import MediaObject from "@/components/media-object"
import { CoinGeckoClient, CoinMarket, Exchange } from "coingecko-api-v3-axios"
import { useTranslation } from "gatsby-plugin-react-i18next"

interface Price {
  currency: string
  value: string
}

function formatMoney(amount: number): string {
  const str = amount.toString()
  const parts = str.split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  parts[1] = (parts[1] || "").slice(0, 2)
  return parts.join(".")
}

const HeroUnit = ({ page }: { page: any }) => {
  const { t } = useTranslation()
  const image = {
    data: page.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: page.featuredImage?.node?.alt || ``,
  }
  const media = image?.data && <GatsbyImage image={image.data} alt={image.alt} />
  const { avatar, name, slug } = page.author.node
  const url = avatar.url.replace("data.madcrypto.com", "www.madcrypto.com")
  const classNames = ["order-reverse"]
  const reviewer = {
    name: "Clement Thibault",
    slug: "clement-thibault",
  }
  if (!media) {
    classNames.push("media-object-no-media")
  }

  return <>
    <MediaObject className={classNames.join(" ")} media={media}>
      <h1>{page.title}</h1>

      <div className="author-info">
        <Link to={`/authors/${slug}/`}>
          <img className="author-avatar" src={url} width={avatar.size} height={avatar.size} />
        </Link>
        <div className="author">
          <Link className="author-name" to={`/authors/${slug}/`}>
            {name}
          </Link>
          <div className="last-updated">
            {t("last-updated-on", { date: page.modified })}
          </div>
          {name !== reviewer.name && <div className="reviewed-by">
            <Link to={`/authors/${reviewer.slug}/`}>
              {t("Reviewed By", { author: reviewer.name })}
            </Link>
          </div>}
        </div>
      </div>
    </MediaObject>
  </>
}

const client = new CoinGeckoClient({
  autoRetry: true,
});

const Index = (props: any) => {
  const { page, sites, coins } = props.data
  if (!page) { return <></> }

  const content = page.content.split("!!!STAKES_LIST!!!");
  const seo = {
    title: page.seoTitle,
    description: page.seoDescription,
    schema: page.seoSchema,
    url: "/staking",
    prefixes: ["en", "x-default"],
  }

  return <Layout {...props} seo={seo} title="Crypto Staking">
    <HeroUnit page={page} />
    <hr />
  
    <div className="container container-columns">
      <div>
        {content.length >= 1 && <div className="page-content" dangerouslySetInnerHTML={{__html: content[0]}}></div>}
        <SiteStakingTable sites={sites.nodes} coins={coins.nodes} />
        {content.length >= 2 && <div className="page-content" dangerouslySetInnerHTML={{__html: content[1]}}></div>}
        <FAQs category="staking" />
      </div>
    </div>
  
  </Layout>
}

const SiteListWidget = (props: { className?: string, sites: { nodes: Site[] } }) => {
  const classNames = ['widget']
  if (props.className) { classNames.push(props.className) }

  return <div className={classNames.join(" ")}>
    <h3>Best <strong>Crypto</strong> Staking</h3>
    <div className="site-list">
      {props.sites
        .nodes
        .sort((a, b) => a.menuOrder - b.menuOrder)
        .slice(0, 3)
        .map((site, i) => <SiteCardSmall key={i} site={site} />)}
    </div>
  </div>
}

const SiteStakingTable = (props: {
  className?: string,
  sites: Site[],
  coins: Coin[],
}) => {
  const classNames = []
  if (props.className) { classNames.push(props.className) }
  const coins = props.coins
  const sites = props.sites

  const [allPrices, setPrices] = useState<Record<string, Exchange>>({})
  useEffect(function () {
    const apiCall = async () => {
      const pricingData = await client.exchanges({})
      const data: Record<string, Exchange> = {}
      for (const exchange of pricingData) {
        data[exchange.id] = exchange
      }
      setPrices(data)
    }

    apiCall()
      .catch(console.error);
  }, [])

  const [allMarkets, setMarkets] = useState<Record<string, CoinMarket>>({})
  useEffect(function () {
    const apiCall = async () => {
      const pricingData = await client.coinMarket({
        ids: coins.map(c => c.coinFields.id).join(","),
        vs_currency: "usd"
      })


      const newPrices: Record<string, CoinMarket> = {}
      for (const price of pricingData) {
        if (!price.id) {
          continue
        }

        newPrices[price.id] = price
      }
      setMarkets(newPrices)
    }

    apiCall()
      .catch(console.error);
  }, [])

  return <div className={classNames.join(" ")}>
    <div className="table-basic coin-staking-list">
      <div className="table-row table-header">
        <div className="column">Asset</div>
        <div className="column hidden-mobile">Price</div>
        <div className="column">Reward Range</div>
        <div className="column cell-small">Highest Reward Provider</div>
        <div className="column cell-large hidden-mobile">More Staking Providers</div>
      </div>
      <div>
        {coins
          .sort((a, b) => a.menuOrder - b.menuOrder)
          .map((coin, i) => <CoinStaking key={i} coin={coin} sites={sites} market={allMarkets[coin.coinFields.id]} prices={allPrices} />)}
      </div>
    </div>
  </div>
  // <div className="column">7-day price change</div>
}

function CoinStaking(props: {
    coin: Coin,
    sites: Site[]
    market: CoinMarket,
    prices: Record<string, Exchange>,
  }) {
  const { coin, sites, market, prices } = props
  const price = market ? `$${formatMoney(market.current_price || 0)}` : ''
  const stakedSites: JSX.Element[] = []
  let minReward = 100
  let maxReward = 0

  /*
  const [coinMarketData, setCoinMarketData] = useState<CoinMarketChartResponse>()
  useEffect(function () {
    const apiCall = async () => {
      let delay = 10000
      try {
        const data = await client.coinIdMarketChart({
          id: coin.coinFields.id,
          days: 7,
          vs_currency: 'usd'
        })
        setCoinMarketData(data)
      } catch (e) {
        console.error(e)
        await new Promise((resolve) => {
          // console.log(`waiting for ${delay}`)
          setTimeout(resolve, delay)
          delay = delay * 2
        })
      }
    }
    apiCall().catch(console.error);
  }, [])

  const chartData = coinMarketData?.prices || []
  const chartOptions: Highcharts.Options = {
    chart: {
      width: 100,
      height: 64,
    },
    colors: [ '#4f7bf6' ],
    tooltip: { enabled: false },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    title: {
      text: ""
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    xAxis: {
      visible: false,
      type: "datetime",
    },
    yAxis: {
      visible: false,
    },
    series: [{
      type: "line",
      data: chartData
    }]
  }
*/

  let highestReward: JSX.Element = <></>
  for (const site of sites) {
    const info = JSON.parse(site.siteFields.stakingInfo)
    let key = (coin.slug in info) ? coin.slug : coin.slug.replace(/\-/g, ' ')
    if (coin.slug === 'xrp') {
      key = 'ripple xrp'
    }

    const staking = info[key]
    const siteInfo = prices[site.siteFields.id]
    if (staking) {
      const image = {
        data: site.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
        alt: site.featuredImage?.node?.alt || ``,
      }
      minReward = Math.min(minReward, staking)
      if (staking >= maxReward) {
        maxReward = Math.max(maxReward, staking)
        if (siteInfo) {
          highestReward = <a key={site.slug} href={`/exchanges/${site.slug}/`} rel="noopener nofollow noreferrer">
            <img src={siteInfo.image} width="32" height="32" />
          </a>
        }
      }
      if (image.data && siteInfo) {
        stakedSites.push(<a key={site.slug} href={`/exchanges/${site.slug}`} rel="noopener nofollow noreferrer">
          <img src={siteInfo.image} width="32" height="32" />
        </a>)
      }
    }
  }

  if (stakedSites.length === 0) {
    return <></>
  }

  return <div className="table-row coin-staking">
    <Link to={`/coins/${coin.slug}/`} className="column coin-name">
      {market && <img width="50" height="50" src={market.image} />}
      {market && <span>{market.name}</span>}
    </Link>
    <div className="column hidden-mobile">{price}</div>
    <div className="column">{stakedSites.length > 0 && `${minReward}% - ${maxReward}%`}</div>
    <div className="column cell-small">{highestReward}</div>
    <div className="column cell-large hidden-mobile">{stakedSites}</div>
  </div>
}

export default Index

export const query = graphql`
  query ($language: String!) {
    page: wpPage(slug: { eq: "staking" }, locale: { locale: { eq: $language} }) {
      ...PageInformation
    }
    sites: allWpSite(filter: { locale: { locale: { eq: $language } } }) {
      nodes {
        title
        slug
        siteFields {
          stakingInfo
          title
          id
          url
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  width: 32
                )
              }
            }
          }
        }
      }
    }
    coins: allWpCoin(filter: {locale: { locale: {eq: $language }}}) {
      nodes {
        title
        slug
        coinFields {
          id
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
